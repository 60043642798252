<template>
  <div>
    <div
      v-if="getTenantFlag('completedReviewsSettings')"
      style="width: 350px"
      class="mx-auto">
      <business-card-details
        :place-id="settingsReviews ? settingsReviews.placeId : undefined"
        :place-name="settingsReviews ? settingsReviews.placeName : undefined"
        :place-address="settingsReviews ? settingsReviews.placeAddress : undefined">
        <vs-button
          class="w-full"
          @click.prevent="$router.push(`/sms-campaigns/create?messageType=${$enums.Campaign.MessageType.TRANSACTIONAL}`)">
          {{ $t('$ReviewsSettingsModule.$ReviewsSettingsView.RequestReviewButtonText') }}
        </vs-button>

        <div class="mt-base text-center">
          <a
            href="#"
            class="link-cta"
            @click.prevent="$emit('setup')">
            {{ $t('$ReviewsSettingsModule.$ReviewsSettingsView.EditSettingButtonText') }}
          </a>
        </div>
      </business-card-details>
    </div>
    <div
      v-else
      class="flex justify-center">
      <div class="text-center greet-user" style="max-width: 450px">
        <img
          src="@/assets/img/reviews/google-my-business-logo.svg"
          alt="Google my business"
          width="auto"
          height="120">
        <h1 class="mt-5">
          {{ $t('$ReviewsSettingsModule.$ReviewsSettingsView.SetupGoogleReviews') }}
        </h1>

        <p class="xl:w-3/4 lg:w-4/5 md:w-2/3 w-4/5 mx-auto my-5">
          {{ $t('$ReviewsSettingsModule.$ReviewsSettingsView.SetupMissingMsg') }}
        </p>

        <a
          href="#"
          class="link-cta"
          @click.prevent="$emit('setup')">
          {{ $t('$ReviewsSettingsModule.$ReviewsSettingsView.SetupNowButtonText') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

// Custom components
import ReviewSettingsBusinessCardDetails from '@/views/modules/reviews/components/ReviewSettingsBusinessCardDetails.vue';

/**
 * Component to view the review settings
 *
 * @module views/modules/reviews/ReviewsSettingsView
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 */
export default {
  name: 'ReviewsSettingsView',
  components: {
    BusinessCardDetails: ReviewSettingsBusinessCardDetails,
  },
  computed: {
    ...mapGetters({
      getTenantFlag: 'auth/getTenantFlag',
      settingsReviews: 'auth/tenantAccountSettingsReviews',
    }),
  },
};
</script>
