<template>
  <div>
    <reviews-settings-view
      v-if="!createOrEdit"
      @setup="createOrEdit = true">
    </reviews-settings-view>

    <transition name="zoom-fade">
      <reviews-settings-create-or-edit
        v-if="createOrEdit"
        class="mb-base"
        @cancel="createOrEdit = false"/>
    </transition>
  </div>

</template>

<script>
import { mapActions } from 'vuex';
import ReviewsSettingsCreateOrEdit from '@/views/modules/reviews/ReviewsSettingsCreateOrEdit.vue';
import ReviewsSettingsView from '@/views/modules/reviews/ReviewsSettingsView.vue';

/**
 * Component to set the account reviews settings
 *
 * @module views/modules/reviews/ReviewsSettings
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {boolean} [createOrEdit=false] - indicate if show crete or edit component
 * @vue-event {void} fetchTenant - fetch the tenant of user
 */
export default {
  name: 'ReviewsSettings',
  components: {
    ReviewsSettingsView,
    ReviewsSettingsCreateOrEdit,
  },
  data() {
    return {
      createOrEdit: false,
    };
  },
  created() {
    this.fetchTenant();
  },
  methods: {
    ...mapActions({
      fetchTenantOfUser: 'auth/fetchTenantOfUser',
    }),
    async fetchTenant() {
      this.$vs.loading({ type: 'radius' });
      await this.fetchTenantOfUser();
      this.$vs.loading.close();
    },
  },
};
</script>
